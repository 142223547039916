import {Scrobble} from "./App";
import {MetricTimestamp, statusAtDate} from "./Metrics";
import tinycolor from "tinycolor2";
import humanizeDuration from 'humanize-duration';

type Pair<T,K> = [T,K];
type Triplet<T,K,N> = [T,K,N];
type Quadruplet<T,K,N,M> = [T,K,N,M];

const day = 86400;

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "m",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "min",
      s: () => "s",
      ms: () => "ms",
    },
  },
  delimiter: " ",
  conjunction: "",
  spacer: ""
});

export function ProgressWidget(props: { metric: MetricTimestamp[], title: string, level_interval: number, color: string, metricType: 'artist' | 'song' }) {
  const current = props.metric[props.metric.length - 1].metric;
  const currentLevel = Math.floor(current / props.level_interval);
  const percent = (p: number) => Math.max((p - currentLevel * props.level_interval) / props.level_interval, 0);
  const analysisPeriods = ([
    [1, "1d", "Last 24h"],
    [7, "7d", "Last week"],
    [30, "1m", "Last month"],
    [90, "3m", "Last 90 days"],
    [180, "6m", "Last 180 days"],
    [360, "12m", "Last year"]
  ] as Triplet<number, string, string>[])
    .map(([x, name, longName]) => {
      const status = statusAtDate(props.metric, (Date.now() / 1000) - day * x);
      return [status, x, name, longName];
    }) as Quadruplet<number, number, string, string>[];
  const extraPercentages = analysisPeriods
    .map(([x, days, name, longName]) => {
      return [percent(x), name];
    }) as Pair<number, string>[];

  return (
    <div className={"bg-gray-100 rounded shadow shadow-2xl w-full h-full flex flex-col items-center py-3"}>
      <h1 className={"text-2xl pb-3"}><span className={"font-bold"}>{current}</span> {props.title}</h1>
      <div className={"w-[90%] h-12"}>
        <LevelBar
          currentLevel={currentLevel}
          percentage={percent(current)}
          color={props.color}
          extraPercentages={extraPercentages}
        />
      </div>
      <div className={"w-full mt-7 px-7"}>
        <PredictionTable current={current} analysisPeriods={analysisPeriods} goal={(currentLevel + 1) * props.level_interval}/>
      </div>
      <div className={"w-full min-w-full px-7 mt-7 mb-5"}>
        <Timeline metric={props.metric} type={props.metricType} color={props.color}/>
      </div>
    </div>
  );
}

export function PredictionTable(props: { current: number, analysisPeriods: Quadruplet<number, number, string, string>[], goal: number }) {
  return <div className={"w-full h-full"}>
    <table className={"w-full table-fixed text-center"}>
      <tr>
        <th>Time period</th>
        <th>Progress</th>
        <th>Per day</th>
        <th>ETA</th>
        <th>Date</th>
      </tr>
      { props.analysisPeriods.map((value, index) => {
        const days = (props.current - value[0]) / value[1];
        const duration = ((props.goal - props.current) / days) * day * 1000;
        return <tr>
          <td>{value[2]}</td>
          <td>{props.current - value[0]}</td>
          <td>{days.toFixed(1)}/d</td>
          <td>{shortEnglishHumanizer(
            duration,
            {
              largest: 2,
            }
          )}</td>
          <td>{(new Date(Date.now() + duration)).toLocaleDateString("de-DE")}</td>
        </tr>;
      }) }
    </table>
  </div>
}

export function LevelBar(props: { currentLevel: number, percentage: number, extraPercentages: Pair<number, string>[], color: string }) {
  const extraBarColor = (idx: number) => tinycolor(props.color).darken(100 - (Math.pow(0.95, idx + 1) * 100)).toString();
  const extrapolationBar = 1;

  return (
    <div className={"w-full h-full"}>
      <div className={"w-full h-[25%] flex flex-row items-center"}>
        <p className={"text-2xl mr-3 text-[#00000000] select-none"}>{props.currentLevel}</p>
        <div className={"relative w-full h-full mr-3"}>
          { [0.0, 0.2, 0.4, 0.6, 0.8].map((val) => {
            return <div
              className={`absolute h-full`}
              style={{
                width: `${(val * 100).toFixed(2)}%`
              }}>
              <div className={"w-full relative"}>
                {
                  val > 0 ?
                    <div className={"absolute -right-0.5 bg-white shadow-xl w-1 h-2 grid place-items-center rounded bg-black"}>
                    </div>
                    : <></>
                }
              </div>
            </div>;
          }).reverse() }
        </div>
      </div>
      <div className={"w-full h-[70%] flex flex-row items-center"}>
        <p className={"text-2xl mr-3"}>{props.currentLevel}</p>
        <div className={"relative w-full h-full bg-gray-300 overflow-hidden rounded-2xl mr-3"}>
          <div className={`absolute h-full mt-[0.06%] grid place-items-center`} style={{ width: `${((props.percentage + (props.percentage - props.extraPercentages[extrapolationBar][0]) * 4.285714286) * 100).toFixed(2)}%` }}>
            <div className={"h-[5%] w-full relative"} style={{ backgroundColor: props.color }}>
            </div>
            <div className={"absolute right-0 mt-[0.06%] w-2 h-2 overflow-hidden translate-x-1/2"}>
              <div className={"w-2 h-2 rotate-45 -translate-x-1/2"} style={{ backgroundColor: props.color }}></div>
            </div>
          </div>
          <div className={`absolute h-full`} style={{ backgroundColor: props.color, width: `${(props.percentage * 100).toFixed(2)}%` }}></div>
          { props.extraPercentages.map(([val, name], idx) => {
            return <>
              <div
                className={`absolute h-full`}
                style={{
                  backgroundColor: extraBarColor(idx),
                  width: `${(val * 100).toFixed(2)}%`
                }}>
              </div>
              {/*(idx === extrapolationBar - 1) ?  : <></>*/}
            </>;
          }) }
        </div>
      </div>
      <div className={"w-full h-[30%] flex flex-row items-center"}>
        <p className={"text-2xl mr-3 text-[#00000000] select-none"}>{props.currentLevel}</p>
        <div className={"relative w-full h-full mr-3"}>
          { props.extraPercentages.map(([val, name], idx) => {
            return <div
              className={`absolute h-full`}
              style={{
                width: `${(val * 100).toFixed(2)}%`
              }}>
              <div className={"w-full relative"}>
                {
                  val > 0 ?
                    <div className={"absolute -right-[1.125rem] bg-white shadow-xl w-9 grid place-items-center rounded"}>
                      <p>{name}</p>
                    </div>
                    : <></>
                }
              </div>
            </div>;
          }).reverse() }
        </div>
      </div>
    </div>
  )
}

export function Timeline(props: { metric: MetricTimestamp[], type: 'artist' | 'song', color: string }) {
  const metric = props.metric.slice().reverse().filter((x) => x.importance > 0).slice(0, 300);
  let previousDate = -1;
  return <div>
    {
      metric.map((ev) => {
        let mainString: string;
        let secondaryString: string;

        if(props.type === "artist") {
          mainString = ev.event.artist;
          secondaryString = ev.event.track;
        } else {
          mainString = ev.event.track;
          secondaryString = ev.event.artist;
        }

        let prefixNode = <></>;

        if(previousDate !== (new Date(ev.date * 1000)).getDate()) {
          let date = new Date(ev.date * 1000);
          prefixNode = <div>
            <h1 className={"text-2xl mt-5"}>{date.toLocaleDateString("de-DE") + ``}</h1>
            <hr/>
          </div>;
        }

        let mainNode;

        const onError = ({ currentTarget }: any) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src="https://lastfm.freetls.fastly.net/i/u/300x300/2a96cbd8b46e442fc41c2b86b821562f.png";
        };

        if(ev.importance >= 3) {
          mainNode = <div className={"flex flex-row px-1 py-0.5 my-1 w-full rounded shadow-inner"} style={{ backgroundColor: tinycolor(props.color).toString() }}>
            <img src={ev.event.image} className={"mr-2 w-11 h-11 rounded relative top-0.5"} onError={onError}/>
            <div className={"flex flex-col"}>
              <a className={"unstyled text-white font-bold"} href={ev.event.url}>{mainString}</a>
              <p className={"text-gray-200"}>{secondaryString}</p>
            </div>
          </div>;
        } else if(ev.importance >= 2) {
          mainNode = <div className={"flex flex-row p-0.5 my-0.5 px-1 w-full rounded shadow-inner"} style={{ backgroundColor: tinycolor(props.color).desaturate(20).lighten(25).toString() }}>
            <img src={ev.event.image} className={"mr-1 w-5 h-5 rounded relative top-0.5"} onError={onError}/>
            <a className={"unstyled"} href={ev.event.url}>{mainString} <span className={"text-gray-500"}>{secondaryString}</span></a>
          </div>;
        } else {
          mainNode = <div className={"flex flex-row m-0.5 px-0.5"}>
            <img src={ev.event.image} className={"mr-1 w-5 h-5 rounded relative top-0.5"} onError={onError}/>
            <a href={ev.event.url} className={"unstyled"}>{mainString} <span className={"text-gray-500"}>{secondaryString}</span></a>
          </div>;
        }

        previousDate = new Date(ev.date * 1000).getDate();

        return <>
          {prefixNode}
          {mainNode}
        </>
      })
    }
  </div>;
}