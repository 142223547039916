import React, {useEffect, useMemo, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import useLocalStorageState from "use-local-storage-state";
// @ts-ignore
import * as lastFm from './scrobbles.js';
import {metricArtists, metricScrobbles, metricTracks} from "./Metrics";
import {ProgressWidget} from "./LevelWidget";

export type Scrobble = {
  album: string,
  artist: string,
  date: number,
  track: string,
  url: string,
  image: string
}

function App() {
  let [scrobbles, setScrobbles] = useLocalStorageState('scrobbles', {
    defaultValue: [] as Scrobble[]
  });
  let [isBackfilling, setIsBackfilling] = useState(false);
  let [isFrontfilling, setIsFrontfilling] = useState(false);
  const isRefreshing = isBackfilling || isFrontfilling;
  let [lastBackfill, setLastBackfill] = useState(0);
  let [lastFrontfill, setLastFrontfill] = useState(0);
  const [time, setTime] = useState(Date.now());
  const lastRefresh = Math.max(lastFrontfill, lastBackfill);

  const firstScrobbleDate = scrobbles[0]?.date;
  const lastScrobbleDate = scrobbles[scrobbles.length - 1]?.date;

  let m_scrobbles = useMemo(() => metricScrobbles(scrobbles), [scrobbles]);
  let m_tracks = useMemo(() => metricTracks(scrobbles), [scrobbles]);
  let m_artists = useMemo(() => metricArtists(scrobbles), [scrobbles]);

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if(!isRefreshing) {
      if(scrobbles === undefined || scrobbles.length === 0) {
        setIsBackfilling(true);

        fetch_newest_to_oldest(undefined, undefined, _page => {
          let page = Array.from(_page);
          page.reverse();
          setScrobbles((prev) => [...page, ...prev]);
        }, () => {
          setLastBackfill(Date.now());
          setLastFrontfill(Date.now());
          setIsBackfilling(false);
        });
      }
      else if(Date.now() - lastRefresh > 10 * 1000) { // 14632
        setIsBackfilling(true);
        setIsFrontfilling(true);

        // Backfill old scrobbles
        fetch_newest_to_oldest(undefined, firstScrobbleDate - 1, _page => {
          let page = Array.from(_page);
          page.reverse();
          setScrobbles((prev) => [...page, ...prev]);
        }, () => {
          setLastBackfill(Date.now());
          setIsBackfilling(false);
        });

        let frontfillBuf = [] as Scrobble[];
        fetch_newest_to_oldest(lastScrobbleDate + 1, undefined, _page => {
          let page = Array.from(_page);
          page.reverse();
          frontfillBuf =  [...page, ...frontfillBuf];
        }, () => {
          setScrobbles((prev) => [...prev, ...frontfillBuf]);
          setLastFrontfill(Date.now());
          setIsFrontfilling(false);
        });
      }
    }
  });

  return (
    <div className={"grid place-items-center py-7"}>
      {
        scrobbles.length > 0 ? <div className={"flex flex-row w-full h-full space-x-7 px-7"}>
          <div className={"w-full h-full"}>
            <ProgressWidget metric={m_artists} title={"Artists"} level_interval={500} color={"#6366f1"} metricType={"artist"}/>
          </div>
          <div className={"w-full h-full"}>
            <ProgressWidget metric={m_tracks} title={"Tracks"} level_interval={1000} color={"#e11d48"} metricType={"song"}/>
          </div>
          <div className={"w-full h-full"}>
            <ProgressWidget metric={m_scrobbles} title={"Scrobbles"} level_interval={5000} color={"#10b981"} metricType={"song"}/>
          </div>
        </div> : <></>
      }
      <button onClick={() => {setScrobbles((prev) => prev.slice(0, prev.length - 1))}}>Pop</button>
    </div>
  );
}

function fetch_newest_to_oldest(_from: number | undefined, _to: number | undefined, onPage: (page: Scrobble[]) => void, onFinish: () => void) {
  const reader = new lastFm.RecentTracks({
    apikey: "b2ee832defe148521a2844a43830d423",
    user: 'utdakfqmfggraqz',
    mapTrack: (rawTrack: any) => ({
      date: Number(rawTrack.date.uts),
      artist: rawTrack.artist['#text'],
      track: rawTrack.name,
      album: rawTrack.album['#text'],
      url: rawTrack.url,
      image: rawTrack.image.find((i: any) => i.size === 'extralarge')['#text'],
    }),
    ...(_from !== undefined) && { from: _from },
    ...(_to !== undefined) && { to: _to },
  });
  reader.on('retry', ({ error, message, retryNum, retryAfterMs, url }: any) => {
    console.error(`Failure (${retryNum}) ${url}: ${message}. Retrying in ${retryAfterMs}`);
  });
  reader.on('progress', console.log);
  const iter = reader[Symbol.asyncIterator]();
  iter.next().then((next_val: any) => {then_func(next_val, iter, onPage, onFinish)});
}

function then_func(val: IteratorResult<any>, iter: any, onPage: (page: Scrobble[]) => void, onFinish: () => void) {
  if(!val.done) {
    onPage(val.value);
    iter.next().then((next_val: any) => {then_func(next_val, iter, onPage, onFinish)});
  } else {
    onFinish();
  }
}

export default App;
