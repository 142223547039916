import {Scrobble} from "./App";

export type MetricTimestamp = {
  date: number,
  metric: number,
  event: Scrobble,
  importance: number // 0: don't display, 1: compact, 2: large, 3: glow
};

export function metricScrobbles(scrobbles: Scrobble[]): MetricTimestamp[] {
  let count = 0;
  return scrobbles.map((x) => {
    count++;
    return {
      date: x.date,
      metric: count,
      event: x,
      importance: 1
    };
  });
}
export function metricTracks(scrobbles: Scrobble[]): MetricTimestamp[] {
  let seen = new Map<string, number>();
  let count = 0;
  return scrobbles.map((x) => {
    let id = x.url;
    if(!seen.has(id)) {
      seen.set(id, 1);
      count++;
      return {
        date: x.date,
        metric: count,
        event: x,
        importance: 1
      };
    } else {
      seen.set(id, seen.get(id)! + 1);
      return { date: x.date, metric: count, event: x, importance: 0 };
    }
  }).map((x: MetricTimestamp) => {
    let id = x.event.url;
    let trackScrobbles = seen.get(id)!;
    if(x.importance > 0) {
      if(trackScrobbles >= 5) {
        x.importance = 3;
      } else if(trackScrobbles >= 2) {
        x.importance = 2;
      }
    }
    return x;
  });
}

export function metricArtists(scrobbles: Scrobble[]): MetricTimestamp[] {
  let seen = new Map<string, Map<string, number>>();
  let count = 0;
  return scrobbles.map((x) => {
    let id = x.artist.trim().toLowerCase();
    let trackId = x.track.trim().toLowerCase();
    if(!seen.has(id)) {
      seen.set(id, new Map<string, number>([[trackId, 1]]));
      count++;
      return {
        date: x.date,
        metric: count,
        event: x,
        importance: 1
      };
    } else {
      let hm = seen.get(id)!;
      if(hm.has(trackId)) {
        hm.set(trackId, hm.get(trackId)! + 1);
      } else {
        hm.set(trackId, 1);
      }
      return { date: x.date, metric: count, event: x, importance: 0 };
    }
  }).map((x: MetricTimestamp) => {
    let id = x.event.artist.trim().toLowerCase();
    if(x.importance > 0) {
      let significant_tracks = Array.from(seen.get(id)!.entries()).filter(([track, num]) => num >= 3).length;
      let medisignificant_tracks = Array.from(seen.get(id)!.entries()).filter(([track, num]) => num >= 2).length;
      let all_tracks = Array.from(seen.get(id)!.entries()).filter(([track, num]) => num >= 1);
      if(significant_tracks >= 1 && medisignificant_tracks >= 2 && all_tracks.length >= 4) {
        x.importance = 3;
      } else if(all_tracks.map(([str, num]) => num).reduce((a, b) => a+b) >= 5) {
        x.importance = 2;
      }
    }
    return x;
  });
}

export function statusAtDate(metric: MetricTimestamp[], date: number): number {
  let idx = metric.findIndex((x) => x.date > date) - 1;
  if(idx < 0) {
    return 0;
  }
  return metric[idx].metric;
}